import { NgModule } from '@angular/core';

import { PersistenceModule } from 'angular-persistence';

// SERVICES
import { CacheService } from './cache.service';
import { SharedService } from './shared.service';
import { HttpService } from './http.service';

@NgModule({
  imports: [
    PersistenceModule
  ],
  exports: [],
  declarations: [],
  providers: [
    CacheService,
    SharedService,
    HttpService
  ],
})
export class ServicesModule { }
