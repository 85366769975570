import { Injectable } from '@angular/core';
import { Network } from '@ionic-native/network/ngx';
import { AlertController, LoadingController, ToastController, Platform } from '@ionic/angular';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Observable, interval, BehaviorSubject, fromEvent } from 'rxjs';
import { Router } from '@angular/router';
import { CacheService } from './cache.service';

// NGRX
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { setEnSesion } from '../redux/app.actions';

// CONSTANTES
import { PAGINA_LOGIN, STR_CACHE_EN_SESION } from '../constants';
import { IUsuario } from '../interfaces';

@Injectable()
export class SharedService {

  public isDisconnected: boolean = false;
  public loader: any;
  public params = {};
  public settings: { cache: boolean, isDark: boolean } = { cache: false, isDark: false };
  public isPremium: boolean = false;
  public firebaseUser: { uid: string, email: string, name?: string, photo?: string };
  public connectionType: any;

  public onLineSubject: BehaviorSubject<boolean>;
  constructor(
    private store: Store<AppState>,
    private network: Network,
    private platform: Platform,
    public loadingController: LoadingController,
    private nativeStorage: NativeStorage,
    public toastController: ToastController,
    public alertController: AlertController,
    private cacheService: CacheService,
    private router: Router
  ) {

    this.onNetworkChange();

    this.onLineSubject = new BehaviorSubject<boolean>(true);

    const handleOnlineChange = (online: boolean) => {
      if (online !== this.onLineSubject.getValue()) {
        this.onLineSubject.next(online);
      }
    };

    handleOnlineChange(navigator.onLine);

    fromEvent(window, 'online').subscribe(() => handleOnlineChange(true));
    fromEvent(window, 'offline').subscribe(() => handleOnlineChange(false));

  }

  onNetworkChange() {

    this.connectionType = this.network.type;

    this.network.onDisconnect().subscribe(() => {
      this.isDisconnected = true;
      this.presentToast('La conexión a internet se ha perdido')
    });

    this.network.onConnect().subscribe(() => this.isDisconnected = false);

  }

  updateConnectionType() {

    this.connectionType = this.network.type;

  }

  onDisconnect(): Observable<any> {

    return this.network.onDisconnect();

  }

  onConnect(): Observable<any> {

    return this.network.onConnect();

  }

  getNetworkType() {

    return this.network.type;

  }

  async presentAlert(payload: { header: 'Información' | 'Aviso', message: string | any }): Promise<void> {

    let mensaje = typeof payload.message === 'string' ? payload.message : JSON.stringify(payload.message);

    mensaje = mensaje === '{}' ? 'Parece que algo salió mal, por favor contacte a soporte técnico.' : mensaje;

    const alert = await this.alertController.create({
      header: payload.header,
      message: mensaje,
      buttons: ['Aceptar'],
      mode: 'ios'
    });

    return await alert.present();

  }

  async presentAwaitAlert(payload: { header: 'Información' | 'Aviso', message: string | any }): Promise<any> {

    const mensaje = typeof payload.message === 'string' ? payload.message : JSON.stringify(payload.message);

    const alert = await this.alertController.create({
      header: payload.header,
      message: mensaje,
      buttons: ['Aceptar'],
      mode: 'ios'
    });

    await alert.present();
    return await alert.onDidDismiss();

  }

  async presentToast(message: string) {

    const mensaje = typeof message === 'string' ? message : JSON.stringify(message);

    const toast = await this.toastController.create({
      message: mensaje,
      duration: 3000,
      keyboardClose: false,
      mode: 'md'
    });

    return await toast.present();

  }

  async presentLoading(message: string) {

    this.loader = await this.loadingController.create({
      spinner: 'bubbles',
      message,
      translucent: true,
      mode: 'md'
    });

    return await this.loader.present();

  }

  async dismissLoader(): Promise<any> {

    return await this.loader.dismiss();

  }

  refreshToken(): Observable<any> {

    return interval(60000);

  }

  async getToken() {

    let token = '';

    if (this.platform.is('cordova')) {

      token = await this.nativeStorage.getItem('AuthToken');

    } else {

      token = localStorage.getItem('IPSAppCredentials');

    }

    return token;

  }

  async cerrarSesion(usuario?: IUsuario) {

    await this.cacheService.setPropertyToCache(STR_CACHE_EN_SESION, { sesion: false });
    await this.cacheService.cleanPropertiesCache();
    this.store.dispatch(setEnSesion({ enSesion: false }));
    await this.router.navigateByUrl(PAGINA_LOGIN, { skipLocationChange: false, replaceUrl: true });

    if (usuario?.rol === 'ADMINISTRADOR' || usuario?.rol === 'COBRANZA') {

      await this.cacheService.setPropertyToCache('email', { email: usuario.usuario });

    }

    window.location.reload();

  }

}
