import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

// SERVICES
import { CacheService } from './cache.service';
import { SharedService } from './shared.service';

// CONSTANTES
import { STR_CACHE_TOKEN } from './../constants/index';

// INTERFACES
import { IToken } from '../interfaces';

// UTILS
import { getSimpleStringEncrypted } from './../utils/crypto';

@Injectable()
export class HttpService {
  constructor(
    private httpClient: HttpClient,
    private cacheService: CacheService,
    private sharedService: SharedService
  ) {}

  async http(
    endpoint: string,
    api = 'default',
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' = 'GET',
    payload = {},
    queryParams = {}
  ): Promise<any> {
    try {
      const params = this.establecerQueryParams(queryParams);

      const tokenCache: IToken = await this.cacheService.getPropertyFromCache(
        STR_CACHE_TOKEN
      );

      let headers: HttpHeaders;
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: '*/*',
        token: tokenCache?.token ? tokenCache?.token : '',
        'Access-Control-Allow-Origin': '*',
      });

      // const baseURL = 'http://localhost:3000/dev/api';
      // const baseURL = 'https://wkydpf7vsb.execute-api.us-east-1.amazonaws.com/dev/api';
      // const baseURL = 'https://2fogxq74e8.execute-api.us-east-1.amazonaws.com/dev/api';
      // const baseURL = 'https://lpdbfile24.execute-api.us-east-1.amazonaws.com/dev/api';
      // const baseURL = 'https://vpketk8yc7.execute-api.us-west-1.amazonaws.com/prod/api';

      const baseURL =
        'https://gomo5qdmvb.execute-api.us-west-1.amazonaws.com/prod/api';

      // https://gomo5qdmvb.execute-api.us-west-1.amazonaws.com/prod/api
      // https://wkydpf7vsb.execute-api.us-east-1.amazonaws.com/dev/api

      let url = '';

      url = `${baseURL}/${endpoint}`;

      let response: any;

      switch (method) {
        case 'GET':
          response = await this.httpClient
            .get(url, { headers, params })
            .toPromise();
          break;
        case 'POST':
          response = await this.httpClient
            .post(url, JSON.stringify(payload), { headers })
            .toPromise();
          break;
        case 'PUT':
          response = await this.httpClient
            .put(url, JSON.stringify(payload), { headers })
            .toPromise();
          break;
        case 'PATCH':
          response = await this.httpClient
            .patch(url, JSON.stringify(payload), { headers })
            .toPromise();
          break;
        case 'DELETE':
          response = await this.httpClient.delete(url, { headers }).toPromise();
          break;
      }

      return Promise.resolve(response);
    } catch (error) {
      return this.errorHandler(error);
    }
  }

  private errorHandler(error: any): Promise<string> {
    if (error?.error?.status === 428) {
      return Promise.reject(error.error);
    }

    if (typeof error === 'string') {
      return Promise.reject(error);
    }
    if (error?.error?.error && typeof error?.error?.error === 'string') {
      return Promise.reject(error.error.error);
    }
    if (error?.error?.mensaje && typeof error?.error?.mensaje === 'string') {
      return Promise.reject(error.error.mensaje);
    }
    if (error.status === 401) {
      this.sharedService.cerrarSesion();
      return Promise.reject('No cuenta con una sesión activa.');
    }
    return Promise.reject(
      'Parece que algo salió mal, por favor contacte a soporte técnico.'
    );
  }

  private establecerQueryParams(objeto: any): HttpParams {
    let parametros = new HttpParams();
    for (const llave in objeto) {
      if (Object.prototype.hasOwnProperty.call(objeto, llave)) {
        const element = objeto[llave];
        parametros = parametros.set(llave, element);
      }
    }

    return parametros;
  }
}

// Actualizar para nuevo despliegue
export const VERSION = '19.0.0.';
