import CryptoJS from 'crypto-js';
import { AES } from 'crypto-js/';

import SimpleCrypto from 'simple-crypto-js';

export const getStringEncrypted = (value: string) => {

  const secret = 'UvjtzmZa5bwWVWYdcvGH9vwqwEwNGQy2HWjANyyj';
  const encrypted = AES.encrypt(value, secret).toString();
  return encrypted;

};

export const getStringDencrypted = (value: string) => {

  const secret = 'UvjtzmZa5bwWVWYdcvGH9vwqwEwNGQy2HWjANyyj';
  value = value.replace(/\s/g, '+');
  const bytes = AES.decrypt(value, secret);
  const response = bytes.toString(CryptoJS.enc.Utf8);
  return response;

};

export const getSimpleStringEncrypted = (value: any) => {

  const secret = 'Ssv5lvi5LEQOGu4fl3jPaq7Z49hhst1yaL3YvVnt';
  const simpleEncryp = new SimpleCrypto(secret);

  const response = simpleEncryp.encrypt(value);
  return response;
};

export const getSimpleStringDencrypted = (value: string) => {

  const secret = 'Ssv5lvi5LEQOGu4fl3jPaq7Z49hhst1yaL3YvVnt';
  const simpleEncryp = new SimpleCrypto(secret);

  const response = simpleEncryp.decrypt(value);
  return response;
};
