// tslint:disable:max-line-length
import { CacheService } from './../services/cache.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';

// SERVICIOS
import { SharedService } from './../services/shared.service';

// INTERFACES
import { IUsuario, IToken } from '../interfaces';

// CONSTANTES
import { STR_CACHE_USUARIO, STR_CACHE_TOKEN } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class ValidarCredencialesGuard implements CanActivate {

  constructor(
    private sharedService: SharedService,
    private cacheService: CacheService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {

    try {

      const tokenCache: IToken = await this.cacheService.getPropertyFromCache(STR_CACHE_TOKEN);

      if (!tokenCache?.token) {
        await this.sharedService.presentAlert({ header: 'Aviso', message: 'No cuenta con una sesión activa.' });
        this.sharedService.cerrarSesion();
        return Promise.reject(false);
      } else {

        const usuarioCache: IUsuario = await this.cacheService.getPropertyFromCache(STR_CACHE_USUARIO);

        const { rol } = usuarioCache;

        if (rol && rol !== route.data.permiso) {

          if (route?.data?.permiso.includes('-')) {

            const permisos = route.data.permiso.split('-');

            if (permisos.includes(rol)) {

              return Promise.resolve(true);

            } else {

              await this.sharedService.presentAwaitAlert({ header: 'Aviso', message: 'No cuenta con los permisos para realizar esta acción.' });
              return Promise.reject(false);

            }

          } else {

            await this.sharedService.presentAwaitAlert({ header: 'Aviso', message: 'No cuenta con los permisos para realizar esta acción.' });
            return Promise.reject(false);

          }

        }

        return Promise.resolve(true);

      }

    } catch (error) {

      this.sharedService.cerrarSesion();
      return Promise.resolve(false);

    }
  }

}
