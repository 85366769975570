import { Component, OnInit, OnDestroy } from '@angular/core';

// PLATFORM
import { Platform } from '@ionic/angular';

// RXJS
import { Subscription } from 'rxjs';

// NGRX
import { Store } from '@ngrx/store';
import { AppState } from './app.reducer';
import {
  setEnSesion,
  setEsMovil,
  setEstado,
  setIsOnLine,
  setUsuario,
  setFechaFondos,
  setEsListaCobranza,
} from './redux/app.actions';

// SERVICES
import { SharedService } from './services/shared.service';
import { CacheService } from './services/cache.service';

// INTERFACES
import { ICatalogo, IOpcionMenu, IUsuario, IEnSesion } from './interfaces';

// CONSTANTES
import {
  PAGINAS_MENU,
  STR_CACHE_EN_SESION,
  STR_CACHE_ESTADO,
  STR_CACHE_USUARIO,
} from './constants';

// UTILS
import { obtenerFechaActual } from './utils/fechas';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  onLineSubscription: Subscription;

  paginasApp: Array<IOpcionMenu> = [];

  appSubscription: Subscription;
  enSesion: boolean;
  usuario: IUsuario;
  estado: ICatalogo = null;

  constructor(
    private store: Store<AppState>,
    private sharedService: SharedService,
    private platform: Platform,
    private cacheService: CacheService
  ) {
    this.paginasApp = PAGINAS_MENU.filter(
      (p) => p.id !== 'depositos' && p.id !== 'mermas'
    );
  }

  async ngOnInit(): Promise<any> {
    this.onLineSubscription = this.sharedService.onLineSubject.subscribe(
      (value) => {
        this.sharedService.presentToast(
          value ? 'Usted cuenta con internet' : 'Usted no cuenta con internet'
        );
        this.store.dispatch(setIsOnLine({ isOnLine: value }));
      }
    );

    if (
      this.platform.platforms().includes('ios') ||
      this.platform.platforms().includes('android')
    ) {
      this.store.dispatch(setEsMovil({ esMovil: true }));
    } else {
      this.store.dispatch(setEsMovil({ esMovil: false }));
    }

    // NGRX
    this.appSubscription = this.store
      .select('app')
      .subscribe(({ enSesion, usuario, estado }) => {
        this.enSesion = enSesion;
        this.usuario = usuario;
        this.estado = estado;
      });

    // SESION
    const sesionCache: IEnSesion = await this.cacheService.getPropertyFromCache(
      STR_CACHE_EN_SESION
    );
    if (sesionCache?.sesion) {
      this.store.dispatch(setEnSesion({ enSesion: sesionCache?.sesion }));
    }

    // ESTADO
    const estadoCache: ICatalogo = await this.cacheService.getPropertyFromCache(
      STR_CACHE_ESTADO
    );
    this.store.dispatch(setEstado({ estado: estadoCache || null }));

    const usuarioCache: IUsuario = await this.cacheService.getPropertyFromCache(
      STR_CACHE_USUARIO
    );

    delete usuarioCache?.contrasenia;

    this.store.dispatch(setUsuario({ usuario: usuarioCache || null }));

    this.store.dispatch(setFechaFondos({ fechaFondos: obtenerFechaActual() }));

    this.store.dispatch(setEsListaCobranza({ esListaCobranza: true }));

    if (
      this.usuario?.rol === 'ADMINISTRADOR' ||
      this.usuario?.rol === 'COBRANZA'
    ) {
      await this.sharedService.cerrarSesion(this.usuario);
    }
  }

  async ngOnDestroy(): Promise<any> {
    if (this.onLineSubscription) {
      this.onLineSubscription.unsubscribe();
    }

    await this.cacheService.setPropertyToCache(STR_CACHE_EN_SESION, {
      sesion: false,
    });
  }

  async cerrarSesionClick() {
    await this.sharedService.cerrarSesion();
  }
}
