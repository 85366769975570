import { Injectable } from '@angular/core';

// ANGULAR PERSISTENCE
import { PersistenceService, StorageType } from 'angular-persistence';

// UTILS
import { getStringDencrypted, getStringEncrypted } from './../utils/crypto';

// CONSTANTES
import {
  STR_CACHE_USUARIO,
  STR_CACHE_TOKEN,
  STR_CACHE_ESTADO,
  STR_CACHE_USUARIO_G,
  STR_CACHE_EN_SESION,
  STR_CACHE_ESTADOS_API
} from './../constants/index';

@Injectable()
export class CacheService {


  constructor(private persistenceService: PersistenceService) { }

  async getPropertyFromCache(property: string) {

    const data = this.persistenceService.get(property, StorageType.LOCAL);
    let dataDecrypted = null;
    if (data) {
      dataDecrypted = getStringDencrypted(data?.data);
    }
    return await data ? JSON.parse(dataDecrypted) : null;

  }

  async setPropertyToCache(property: string, payload: any) {

    const data = getStringEncrypted(JSON.stringify(payload));

    return await new Promise((resolve, reject) =>
      this.persistenceService.set(property, { data }, { type: StorageType.LOCAL })
        ? resolve(true)
        : reject(false));

  }

  async cleanPropertiesCache() {

    await this.persistenceService.remove(STR_CACHE_USUARIO, StorageType.LOCAL);
    await this.persistenceService.remove(STR_CACHE_ESTADO, StorageType.LOCAL);
    await this.persistenceService.remove(STR_CACHE_TOKEN, StorageType.LOCAL);
    await this.persistenceService.remove(STR_CACHE_USUARIO_G, StorageType.LOCAL);
    await this.persistenceService.remove(STR_CACHE_EN_SESION, StorageType.LOCAL);
    await this.persistenceService.remove(STR_CACHE_ESTADOS_API, StorageType.LOCAL);
    await this.persistenceService.remove('', StorageType.LOCAL);


  }

  async cleanPropertyCache(property: string) {

    await this.persistenceService.remove(property, StorageType.LOCAL);

  }

}
