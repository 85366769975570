// INTERFACES
import { IOpcionMenu } from '../interfaces';


// CACHE
export const STR_CACHE_TOKEN = 'cache/token';
export const STR_CACHE_USUARIO_G = 'cache/usuario/g';
export const STR_CACHE_USUARIO = 'cache/usuario';
export const STR_CACHE_ESTADO = 'cache/estado';
export const STR_CACHE_ESTADOS_API = 'cache/estados/api';

// CACHE CONFIGURACIÓN
export const STR_CACHE_EN_SESION = 'cache/en/sesion';

// PAGINAS
export const PAGINA_LOGIN = 'login';
export const PAGINA_RECUPERAR_CONTRASENIA = 'recuperar/contrasenia/:token';

export const PAGINA_ESTADOS = 'estados';
export const PAGINA_REGISTRO_SANDIAS_AGRICULTOR = 'registro-sandia-agricultor';
export const PAGINA_REGISTRO_SANDIAS_CLIENTE = 'registro-sandia-cliente';
export const PAGINA_REGISTRO_SANDIAS_ENCARGADO = 'registro-sandia-encargado';
export const PAGINA_ASIGNAR_ESTADO = 'asignar-estado';
export const PAGINA_DEPOSITOS = 'depositos';
export const PAGINA_LISTA_CLIENTES = 'lista-clientes';
export const PAGINA_REGISTRO_CLIENTE = 'registro-cliente';
export const PAGINA_MERMAS = 'mermas';
export const PAGINA_LISTA_AGRICULTORES = 'lista-agricultores';
export const PAGINA_LISTA_ENCARGADOS = 'lista-encargados';
export const PAGINA_DATOS_CLIENTE = 'datos-cliente/:cliente';
export const PAGINA_DATOS_ENCARGADO = 'datos-encargado/:encargado';
// export const PAGINA_DATOS_CLIENTE = 'datos-cliente';
export const PAGINA_REGISTRO_AGRICULTOR = 'registro-agricultor';
export const PAGINA_REGISTRO_ENCARGADO = 'registro-encargado';
export const PAGINA_DATOS_AGRICULTOR = 'datos-agricultor/:agricultor';
export const PAGINA_UTILIDADES = 'utilidades';
export const PAGINA_FONDOS = 'fondos';
export const PAGINA_DATOS_UTILIDADES = 'datos-utilidades';
export const PAGINA_LISTA_UTILIDADES = 'lista-utilidades';
export const PAGINA_VIAJES_CLIENTE = 'lista-viajes-cliente';

export const PAGINAS_MENU: Array<IOpcionMenu> = [
  {
    id: 'estados', // [0]
    titulo: 'Estados',
    ruta: PAGINA_ESTADOS,
    icono: 'apps'
  },
  {
    id: 'registro_cliente', // [1]
    titulo: 'Registrar cliente',
    ruta: PAGINA_REGISTRO_CLIENTE,
    icono: 'bag-add'
  },
  {
    id: 'registro_agricultor', // [2]
    titulo: 'Registrar agricultor',
    ruta: PAGINA_REGISTRO_AGRICULTOR,
    icono: 'bag-handle'
  },
  {
    id: 'lista_cliente', // [3]
    titulo: 'Lista de clientes',
    ruta: PAGINA_LISTA_CLIENTES,
    icono: 'list'
  },
  {
    id: 'lista_agricultores', // [4]
    titulo: 'Lista de agricultores',
    ruta: PAGINA_LISTA_AGRICULTORES,
    icono: 'list-circle'
  },
  {
    id: 'sandias_cliente', // [5]
    titulo: 'Registrar Ventas',
    ruta: PAGINA_REGISTRO_SANDIAS_CLIENTE,
    icono: 'wallet'
  },
  {
    id: 'sandias_agricultor', // [6]
    titulo: 'Registrar compras',
    ruta: PAGINA_REGISTRO_SANDIAS_AGRICULTOR,
    icono: 'cart'
  },
  {
    id: 'depositos', // [7]
    titulo: 'Depósitos',
    ruta: PAGINA_DEPOSITOS,
    icono: 'cash'
  },
  {
    id: 'mermas', // [8]
    titulo: 'Mermas',
    ruta: PAGINA_MERMAS,
    icono: 'ban'
  },
  {
    id: 'sandias_encargado', // [9]
    titulo: 'Registrar Kilos',
    ruta: PAGINA_REGISTRO_SANDIAS_ENCARGADO,
    icono: 'wallet'
  },
  {
    id: 'registro_encargado', // [10]
    titulo: 'Registrar encargado',
    ruta: PAGINA_REGISTRO_ENCARGADO,
    icono: 'bag-handle'
  },
  {
    id: 'lista_encargados', // [11]
    titulo: 'Lista de encargados',
    ruta: PAGINA_LISTA_ENCARGADOS,
    icono: 'list-circle'
  },
  {
    id: 'utilidades', // [12]
    titulo: 'Utilidades',
    ruta: PAGINA_UTILIDADES,
    icono: 'book'
  },
  {
    id: 'fondos', // [13]
    titulo: 'Fondos',
    ruta: PAGINA_FONDOS,
    icono: 'cash'
  },
  {
    id: 'asignar_estado', // [14]
    titulo: 'Asignar estados',
    ruta: PAGINA_ASIGNAR_ESTADO,
    icono: 'flag'
  },
];


// EXPRESIONES REGULARES
export const ALFABETICA = /^[A-z üéáíóúñÑÁÉÍÓÚ]+$/;
export const NUMERICA = /^[0-9]+$/;
export const MONEDA = /^[0-9.]+$/;

