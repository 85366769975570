import { createAction, props } from '@ngrx/store';

// INTERFACES
import { IUsuario, ICatalogo } from 'src/app/interfaces';

// ACCIONES PARA ONLINE
export const setIsOnLine = createAction(
  '[OnLine] setIsOnLine',
  props<{ isOnLine: boolean }>()
);
// **** FIN ****

// ACCIONES PARA MOVIL
export const setEsMovil = createAction(
  '[Movil] setEsMovil',
  props<{ esMovil: boolean }>()
);
// **** FIN ****

// ACCIONES PARA SESION
export const setEnSesion = createAction(
  '[Sesion] setEnSesion',
  props<{ enSesion: boolean }>()
);
// **** FIN ****

// ACCIONES PARA USUARIO
export const setUsuario = createAction(
  '[Usuario] setUsuario',
  props<{ usuario: IUsuario }>()
);

export const unSetUsuario = createAction('[Usuario] unSetUsuario');
// **** FIN ****

// ACCIONES PARA ESTADO
export const setEstado = createAction(
  '[Estado] setEstado',
  props<{ estado: ICatalogo }>()
);

export const unSetEstado = createAction('[Estado] unSetEstado');
// **** FIN ****

// ACCIONES PARA FECHA FONDOS
export const setFechaFondos = createAction(
  '[FechaFondos] setFechaFondos',
  props<{ fechaFondos: string }>()
);
// **** FIN ****

// ACCIONES PARA EN LISTA COBRANZA
export const setEsListaCobranza = createAction(
  '[EnListaCobranza] setEsListaCobranza',
  props<{ esListaCobranza: boolean }>()
);
// **** FIN ****
