import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeMx from '@angular/common/locales/es';
import localeMxExtra from '@angular/common/locales/extra/es-MX';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

import { Network } from '@ionic-native/network/ngx';

// NGRX
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { appReducers } from './app.reducer';

// NATIVE STORAGE
import { NativeStorage } from '@ionic-native/native-storage/ngx';

// SERVICES MODULE
import { ServicesModule } from './services/services.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

// LOCALE DATA
registerLocaleData(localeMx, 'es', localeMxExtra);

// POPOVER
import { OpcionesPopoverComponent } from './popover/cliente-opciones.popover.component';

@NgModule({
  declarations: [AppComponent, OpcionesPopoverComponent],
  entryComponents: [OpcionesPopoverComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ServicesModule,
    StoreModule.forRoot( appReducers ),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    NativeStorage,
    Network,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
